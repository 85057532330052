<template>
  <div class="input">
    <ValidationProvider
      tag="div"
      :name="name"
      :rules="rules"
      v-slot="{ errors, ariaMsg }"
    >
      <label
        class="fs-14 fw-600 mb-1x input__label"
        :style="{ '--label-color': labelColor }"
        v-if="label"
      >
        {{ label }}
      </label>
      <div
        class="input-wrapper"
        :class="{
          'input-wrapper--focused': isFocused,
          'input-wrapper--error': errors.length
        }"
        :style="{ '--border-color': borderColor }"
      >
        <input
          class="input__box"
          :type="inputType"
          :placeholder="placeholder"
          v-model="inputText"
          :name="name"
          v-bind="$attrs"
          :disabled="disabled || disabled === ''"
          @focus="isFocused = true"
          @blur="isFocused = false"
        />

        <button
          v-if="type === 'password'"
          @click.prevent="setInputType"
          class="input__toggle-pass-text"
        >
          <template v-if="inputType === 'text'">
            <template v-if="passwordToggleType === 'text'">
              <span class="fw-400 fs-8" style="color: #909090">HIDE</span>
            </template>
            <template v-else>
              <div style="width: 16px; height: 16px">
                <img src="../../assets/icons/eye-hide.svg" alt="eye-hide" />
              </div>
            </template>
          </template>
          <template v-else>
            <div
              style="width: 16px; height: 16px"
              v-if="passwordToggleType === 'icon'"
            >
              <img src="../../assets/icons/eye-show.svg" alt="eye-show" />
            </div>
            <span class="fw-400 fs-8" style="color: #909090" v-else>SHOW</span>
          </template>
        </button>
      </div>
      <span
        class="d-block text-red fs-12"
        style="text-align: left"
        v-bind="ariaMsg"
      >
        {{ errors[0] }}
      </span>
    </ValidationProvider>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
  name: 'Input',
  props: {
    type: {
      type: String,
      default: 'text'
    },
    placeholder: {
      type: String,
      default: 'Placeholder text'
    },
    value: {
      type: String
    },
    rules: {
      type: [String, Object],
      default: null
    },
    name: {
      type: String,
      default: ''
    },
    passwordToggleType: {
      type: String,
      default: 'text',
      validator: (value) => ['icon', 'text'].includes(value)
    },
    label: {
      type: String,
      default: ''
    },
    borderColor: {
      type: String,
      default: '#909090'
    },
    labelColor: {
      type: String,
      default: '#505565'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ValidationProvider
  },
  data() {
    return {
      inputType: this.type,
      isFocused: false
    };
  },
  computed: {
    inputText: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  },
  methods: {
    setInputType() {
      this.inputType = this.inputType === 'text' ? 'password' : 'text';
    }
  }
};
</script>

<style scoped lang="scss">
@use './input.scss';

.input-wrapper {
  display: flex;
  border: 1px solid var(--border-color);
  border-radius: 4px;
}

.input-wrapper--focused {
  border: 1px solid #65afe9;
  box-shadow: 0px 0px 7px 1px rgba(#65afe9, 0.5);
}

.input-wrapper--error {
  border: 1px solid rgb(255, 0, 0);
}

.input-wrapper--error.input-wrapper--focused {
  box-shadow: 0px 0px 7px 1px rgba(255, 0, 0, 0.5);
}

.input__box {
  flex: 1;
  // background-color: red;
}
</style>
